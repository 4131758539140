import router from './router'
import store from './store'
import Token from '@/utils/token'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

// 白名单
const whiteList = ['login', 'project-preview']

router.beforeEach((to, from, next) => {
  // console.log(to, from)
  NProgress.start() // start progress bar
  const toKen = Token.get()

  if (toKen) {
    /* has token */
    next()
  } else {
    NProgress.done()
    if (to.name === 'bind' && store.state.user.tempToken) {
      // 微信绑定
      next()
    } else if (whiteList.includes(to.name)) {
      // 在免登录白名单，直接进入
      next()
    } else {
      // const query = { redirect: to.fullPath }
      next({ path: '/login' })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})


import { detectDeviceType } from '@/utils/utils'

export default {
  namespaced: true,
  state: {
    platform: detectDeviceType(),
    DOC_MAX_WIDTH: 750,

    // 微信IOS的浏览器在history模式下需要使用刚进页面的url
    IOS_CACHE_URL: location.href.split('#')[0]
  },

  mutations: {
    setRemValue (state) {
      const docWidth = document.documentElement.getBoundingClientRect().width
      console.log(docWidth)
      if (state.platform === 'Desktop') {
        if (docWidth > 750) {
          state.DOC_MAX_WIDTH = document.querySelector('#editContainer').getBoundingClientRect().width
        } else {
          state.DOC_MAX_WIDTH = docWidth
        }
      } else {
        state.DOC_MAX_WIDTH = docWidth
      }
      console.log(state.DOC_MAX_WIDTH)
    }
  },

  actions: {
  }
}

import request from '@/utils/request'

// 用户项目列表
export function getProjectList (params) {
  return request({
    url: `/tenant/tenantApp/applyList`,
    params
  })
}
// 用户项目列表
export function getPrivateProjectList (params) {
  return request({
    url: `/tenant/tenantApp/tenantApplyList`,
    params
  })
}

// 用户项目详情-详情
export function getProjectDetailByCode (code) {
  return request({
    url: `/tenant/tenantApp/info/${ code }`
  })
}

// 用户项目/私有模板修改
// 更新项目信息
export function updateProjectInfo (data) {
  return request({
    url: `/tenant/tenantApp/update`,
    method: 'PUT',
    data
  })
}

// 用户项目/私有模板添加
// 项目提交
export function saveProjectBaseInfo (data) {
  return request({
    url: `/tenant/tenantApp/save`,
    method: 'POST',
    data
  })
}

// 用户项目删除
export function deleteProject (code) {
  return request({
    url: `/tenant/tenantApp/applyDelete/${ code }`,
    method: 'DELETE'
  })
}

// 用户项目上下线
export function setProjectLineStatus ({ code, status }) {
  return request({
    url: `/tenant/tenantApp/updateStatus/${ code }/${ status }`
  })
}

// 上传图片并更新项目 cover 字段
export function uploadFileBase64Update (data) {
  return request({
    url: `/tenant/tenantApp/uploadImgAndUpdate`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}

import Vue from 'vue'
import Vant, { Lazyload } from 'vant'
import 'vant/lib/index.less'
import Meta from 'vue-meta'
import Storage from 'vue-ls'

import './action'

Vue.use(Vant)
Vue.use(Lazyload)
Vue.use(Meta)

// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true
})


const options = {
  namespace: 'BB__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local' // storage name session, local, memory
}
Vue.use(Storage, options)

process.env.NODE_ENV !== 'production' && console.warn('UI use lazy-load.')

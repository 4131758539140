import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './permission'
import './core/lazy_use'
import './assets/js/rem'

import './assets/css/templateGlobal.css'
import './assets/css/iconfont.css'

import { VueAxios } from './utils/request'

Vue.use(VueAxios)

Vue.config.productionTip = false

// 系统错误捕获
const errorHandler = (err, vm, info) => {
  console.error(err, vm, info)
  // localLog.add('抛出全局异常', err.toString())
}

Vue.config.errorHandler = errorHandler
Vue.prototype.$throw = (error) => errorHandler(error, this)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import request from '@/utils/request'


// 手机号验证码登录
export function userMobileLogin (data) {
  return request({
    url: '/tenant/mobileLogin',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}

// 账号密码登录
export function userLogin (data) {
  return request({
    url: '/tenant/login',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}

// 修改密码
export function updatePassword (data) {
  return request({
    url: '/tenant/update-password',
    method: 'PUT',
    data
  })
}

// 退出
export function logout () {
  return request({
    url: '/logout'
  })
}

// 发送短信验证码
export function sendMobileCode (data) {
  return request({
    url: '/common/code/sender',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}

// 图形验证码
export function getCaptchaCode () {
  return request({
    url: '/common/code/captcha.jpg',
    // 'arraybuffer', 'blob'
    responseType: 'blob'
  })
}

// 获取Tenant用户信息
export function getUserInfo () {
  return request({
    url: '/tenant/user/info'
  })
}

// 微信登录
// 获取授权链接
export function getAuthUrl (params) {
  return request({
    url: '/common/wechat/authorize',
    params
  })
}
// 微信授权登录
export function wxAuthLogin (params) {
  return request({
    url: '/tenant/wxLogin',
    params
  })
}
// 微信授权登录-新用户绑定
export function wxAuthLoginBind (data, token) {
  return request({
    url: '/tenant/account/bind',
    method: 'POST',
    noToast: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token
    },
    data
  })
}

// 格式化时间
export function dateFormat (date, fmt = 'YYYY-mm-dd HH:MM') {
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    };
  };
  return fmt
}

// 节流
export function throttle (fn, delay = 300) {
  let timer = null
  let last_t = 0
  return function () {
    clearTimeout(timer)
    const context = this
    const args = arguments
    const now = Date.now()
    if (!last_t) last_t = now
    if (now - last_t >= delay) {
      fn.apply(context, args)
      last_t = now
    } else {
      timer = setTimeout(() => {
        fn.apply(context, args)
      }, delay)
    }
  }
}


// // 平滑滚动至顶部
// export const scrollToTop = (dom) => {
//   const top = dom.scrollTop
//   if (top > 0) {
//     window.requestAnimationFrame(() => {
//       scrollToTop(dom)
//     })
//     dom.scrollTop = top - top / 8
//   }
// }
// 平滑滚动至顶部
export const docScrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop
  if (c > 0) {
    window.requestAnimationFrame(docScrollToTop)
    window.scrollTo(0, c - c / 8)
  }
}

// 检测移动/PC设备
export const detectDeviceType = () => (
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ? 'Mobile'
    : 'Desktop'
)

// 判断 ios 终端
export function isIOS () {
  var u = navigator.userAgent
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}

// 返回指定元素的生效样式
export const getStyle = (el, ruleName) => getComputedStyle(el)[ruleName]


// 替换样式
export const replaceStyle = (rule, newStyle) => {
  let ruleStyle = rule.split(';')

  const name = newStyle.split(':')[0]
  ruleStyle = ruleStyle.filter(style => !new RegExp(`^${ name }`).test(style.trim()))

  const textStyle = ruleStyle.join(';')
  return textStyle + newStyle
}

// 元素对用户可见
export const scrollIntoView = (idName) => {
  const dom = document.getElementById(idName)
  dom.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

// 对象转 FormData
export function obj2FormData (obj) {
  const formData = new FormData()

  for (const [key, val] of Object.entries(obj)) {
    formData.append(key, val)
  }

  return formData
}

import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

import getters from './getters'

import app from './modules/app'
import user from './modules/user'
import home from './modules/home'
import boilerplate from './modules/boilerplate'

Vue.use(Vuex)
const debug = ['uat', 'development'].includes(process.env.VUE_APP_ENV)

export default new Vuex.Store({
  strict: debug,
  plugins: debug ? [createLogger()] : [],
  modules: {
    app,
    user,
    home,
    boilerplate
  },
  getters
})

import request from '@/utils/request'

// 公共模板
export function publicTemplateList (params) {
  return request({
    url: `/tenant/template/list`,
    params
  })
}
// 公共模板-详情
export function publicTemplateDetail (code) {
  return request({
    url: `/tenant/template/info/${ code }`
  })
}

// 私有模板
export function privateTemplateList (params) {
  return request({
    url: `/tenant/tenantApp/templateList`,
    params
  })
}
// 私有模板-详情
export function privateTemplateDetail (code) {
  return request({
    url: `/tenant/tenantApp/info/${ code }`
  })
}

// 获取所有模板分类列表
export function getTemplateCategory (parentCode) {
  return request({
    url: `tenant/templateCategory/categoryListByParentCode/${ parentCode }`
  })
}

// 编辑模板-背景列表
export function getBgList (params) {
  return request({
    url: `/tenant/background/list`,
    params
  })
}

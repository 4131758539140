import Vue from 'vue'

const active = Vue.directive('dotDffect', {
  inserted (el) {
    const dotDom = document.createElement('div')
    dotDom.className = 'dot'

    el.style.position = 'relative'
    el.style.overflow = 'hidden'

    el.onclick = function (e) {
      const { clientX, clientY } = e

      const left = el.offsetLeft
      const top = el.offsetTop

      dotDom.style.top = clientY - top + 'px'
      dotDom.style.left = clientX - left + 'px'

      el.appendChild(dotDom)
    }
  }
})


export default active

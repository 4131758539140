import storage from './storage'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { TOKEN_EXPIRE } from '@/config'

const day = 24 * 60 * 60 * 1000

const token = {
  get () {
    return storage.get(ACCESS_TOKEN, '')
  },
  set (value) {
    storage.set(ACCESS_TOKEN, value, TOKEN_EXPIRE * day)
  },
  remove () {
    storage.remove(ACCESS_TOKEN)
  }
}

export default token

import storage from '@/utils/storage'
import { USER_INFO } from '@/store/mutation-types'

import Token from '@/utils/token'
// import { obj2FormData } from '@/utils/utils'
import { dateFormat } from '@/utils/utils'

import { getCaptchaCode, userLogin, userMobileLogin } from '@/api/user'
import { getAccountInfo } from '@/api/account'


import router from '@/router'

export default {
  namespaced: true,
  state: {
    // 用户信息
    userInfo: {},
    // 图形验证码
    captchaImg: '',
    // loading
    btnLoading: false,
    // 临时token
    tempToken: ''
  },

  mutations: {
    setUserInfo (state, userInfo) {
      storage.set(USER_INFO, userInfo)
      state.userInfo = userInfo
    },
    setCaptcha (state, code) {
      state.captchaImg = code
    },
    setBtnLoading (state, bool) {
      state.btnLoading = bool
    },
    setTempToken (state, token) {
      state.tempToken = token
    }
  },

  actions: {
    // 获取图形验证码
    async getCaptchaCode ({ commit }) {
      try {
        const code = await getCaptchaCode()

        const blob = new Blob([code])
        const url = URL.createObjectURL(blob)

        commit('setCaptcha', url)
      } catch (error) {
        console.log(error)
      }
    },
    // 手机验证码登录
    userMobileLogin ({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit('setBtnLoading', true)

        userMobileLogin(params).then(res => {
          const { data, code } = res
          commit('setBtnLoading', false)
          if (code === 0) {
            Token.set(data)
          }
          resolve(res)
        }).catch(err => {
          commit('setBtnLoading', false)
          reject(err)
        })
      })
    },
    // 账户密码登录
    userLogin ({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit('setBtnLoading', true)

        userLogin(params).then(res => {
          const { data, code } = res
          commit('setBtnLoading', false)
          if (code === 0) {
            Token.set(data)
          }
          resolve(res)
        }).catch(err => {
          commit('setBtnLoading', false)
          reject(err)
        })
      })
    },
    // 用户信息
    getUserInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getAccountInfo().then(res => {
          const { data, code } = res
          if (code === 0) {
            // 格式化时间
            const time = new Date(data.lastLoginTime)
            data.lastLoginTime = dateFormat(time)
            commit('setUserInfo', data)
          }
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 微信登录授权跳转
    wxLoginUrl () {
      const href = `${ process.env.VUE_APP_API_HOST }/api/common/wechat/authorize?url=${ encodeURIComponent(process.env.VUE_APP_API_HOST + '/login') }`
      location.href = href
    },
    // 退出登录
    async logout () {
      try {
        // await logout()
        Token.remove()
        router.push(`/login`)
      } catch (error) {
        console.log(error)
      }
    }
  }
}

import { publicTemplateDetail, privateTemplateDetail } from '@/api/template'
import { getProjectDetailByCode } from '@/api/project'

export default {
  namespaced: true,
  state: {
  },

  mutations: {
  },

  actions: {
    async templateDetail ({ commit }, { tempCode, tempType }) {
      try {
        let resData

        if (tempType === '1') {
          resData = await publicTemplateDetail(tempCode)
        } else if (tempType === '2') {
          resData = await privateTemplateDetail(tempCode)
        } else {
          resData = await getProjectDetailByCode(tempCode)
        }

        if (resData.code === 0) {
          return resData.data
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}

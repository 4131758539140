import request from '@/utils/request'

// 用户列表
export function getAccountList (params) {
  return request({
    url: `/tenant/account/list`,
    params
  })
}

// 用户详情
export function getAccountInfo (params) {
  return request({
    url: `/tenant/info`,
    params
  })
}

// eslint-disable-next-line
const title = process.env.VUE_APP_API_APP_NAME

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/index'),
    meta: { title: title }
  },
  {
    path: '/template-detail',
    name: 'template-detail',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/TemplateDetail'),
    meta: { title: title || '预览模板' } // 预览模板
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login'),
    meta: { title: title || '播报系统登录' }
  },
  {
    path: '/bind',
    name: 'bind',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/BindWxLogin'),
    meta: { title: title }
  },
  // 播报
  {
    path: '/template/add',
    name: 'template-add',
    component: () => import(/* webpackChunkName: "template" */ '@/views/template/Add'),
    meta: { title: title || '新增' } // 新增
  },
  // 项目编辑
  {
    path: '/project/edit',
    name: 'project-edit',
    component: () => import(/* webpackChunkName: "template" */ '@/views/boilerplate/EditIndex'),
    meta: { title: title || '项目编辑' } // 项目编辑
  },
  {
    path: '/project/edit-text',
    name: 'project-edit-text',
    component: () => import(/* webpackChunkName: "template" */ '@/views/boilerplate/EditText'),
    meta: { title: title || '编辑文字' } // 编辑文字
  },
  {
    path: '/project/edit-image',
    name: 'project-edit-image',
    component: () => import(/* webpackChunkName: "template" */ '@/views/boilerplate/EditImage'),
    meta: { title: title || '编辑图片' } // 编辑图片
  },
  {
    path: '/project/share/:code',
    name: 'project-share',
    component: () => import(/* webpackChunkName: "template" */ '@/views/boilerplate/EditShare'),
    meta: { title: title || '分享' }
  },
  // 预览项目
  {
    path: '/preview/:code',
    name: 'project-preview',
    component: () => import(/* webpackChunkName: "preview" */ '@/views/Preview/index'),
    meta: { title: title || '项目预览' } // 项目预览
  },
  // 项目
  {
    path: '/project',
    name: 'project',
    component: () => import(/* webpackChunkName: "project" */ '@/views/project/index'),
    meta: { title: title || '项目列表' } // 项目列表
  },
  // 个人中心
  {
    path: '/personal',
    name: 'personal',
    component: () => import(/* webpackChunkName: "personal" */ '@/views/personal/index'),
    meta: { title: title || '个人中心' } // 个人中心
  },
  {
    path: '/update-password',
    name: 'UpdatePassword',
    component: () => import(/* webpackChunkName: "personal" */ '@/views/user/UpdatePassword'),
    meta: { title: title || '修改密码' } // 修改密码
  },
  // {
  //   path: '/account/detail',
  //   name: 'account-detail',
  //   component: () => import(/* webpackChunkName: "account" */ '@/views/account/Detail'),
  //   meta: { title: '用户详情' }
  // },
  {
    path: '/company',
    name: 'company-index',
    component: () => import(/* webpackChunkName: "company" */ '@/views/company/index'),
    meta: { title: title || '公司主体' } // 公司主体
  },
  {
    path: '/company/user',
    name: 'company-user',
    component: () => import(/* webpackChunkName: "account" */ '@/views/company/userList'),
    meta: { title: title || '公司下用户' } // 公司下用户
  },
  {
    path: '/account/list',
    name: 'account-userList',
    component: () => import(/* webpackChunkName: "account" */ '@/views/account/list'),
    meta: { title: title || '个人账号信息' } // 个人账号信息
  },
  {
    path: '/account/detail',
    name: 'account-userList',
    component: () => import(/* webpackChunkName: "account" */ '@/views/account/detail'),
    meta: { title: title || '用户详情' } // 用户详情
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/404'),
    meta: { title: '404' }
  },
  {
    path: '*',
    redirect: '/404'
  }
]

import storage from '@/utils/storage'
import { EDIT_TEMP_NAME } from '@/store/mutation-types'

// import { teamData3 as teamData } from '@/mock/tempDataUat'

export default {
  namespaced: true,
  state: {
    tempOriginInfo: {},
    tempOrigin: {},

    visibleEditShow: false,
    // 编辑文本内容
    editText: {
      idx: -1,
      text: ''
    },
    // 编辑图片内容
    editImage: {
      idx: -1,
      text: ''
    },

    // 预览图片
    previewTemp: {
      name: '',
      url: ''
    },

    // 显示组件
    visibleComponentBox: false,

    // 显示背景框
    visibleBgBox: false
  },

  mutations: {
    // tempOriginInfo
    setTempOriginInfo (state, data) {
      state.tempOriginInfo = data
      console.log(data)

      const localCacheData = storage.get(EDIT_TEMP_NAME, {})

      if (Object.keys(localCacheData).length === 0) {
        console.log('设置新数据')
        const content = JSON.parse(data?.content) // teamData
        storage.set(EDIT_TEMP_NAME, {
          code: data?.code,
          data: content
        })
        state.tempOrigin = content
        return
      }

      if (localCacheData?.code === data?.code) {
        console.log('使用本地缓存模板信息')
        state.tempOrigin = localCacheData?.data
      } else {
        console.log('使用新模板信息', JSON.parse(data?.content))
        state.tempOrigin = JSON.parse(data?.content)
      }
    },
    // tempOrigin
    setTempOrigin (state, data) {
      console.log('缓存模板数据', data)
      storage.set(EDIT_TEMP_NAME, {
        code: state.tempOriginInfo?.code,
        data
      })
      state.tempOrigin = data
    },
    // 控制编辑菜单
    setVisibleEdit (state, type) {
      state.visibleEditShow = type
    },
    // 修改文字内容
    setEditText (state, data) {
      state.editText = data
    },
    // 修改文字内容
    setEditImage (state, data) {
      state.editImage = data
    },
    // 隐藏/显示背景框
    setVisibleBgBox (state, data) {
      state.visibleBgBox = data
    },
    // 隐藏/显示组件
    setVisibleComponent (state, data) {
      state.visibleComponentBox = data
    }
  },

  actions: {
  }
}

// token
export const ACCESS_TOKEN = 'ACCESS_TOKEN_NEW'
// 用户信息
export const USER_INFO = 'USER_INFO'

// 编辑模板
export const EDIT_TEMP_NAME = 'CACHE_EDIT_TEMP'
// 编辑预览内容
export const EDIT_PREVIEW_TEMP_NAME = 'EDIT_PREVIEW_TEMP_NAME'
// 项目预览内容
export const EDIT_PREVIEW_BACK_CODE = 'EDIT_PREVIEW_BACK_CODE'


import axios from 'axios'
import { VueAxios } from './axios'
import { Notify } from 'vant'

import store from '@/store'

import storage from './storage'
import { ACCESS_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // withCredentials: true,
  timeout: 10000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  console.warn(error.response)
  if (error.response) {
    const { status, statusText } = error.response

    const statusCode = [502, 500]
    if (statusCode.includes(status)) {
      // storage.remove(ACCESS_TOKEN)
      Notify({ type: 'danger', message: `${ status }: ${ statusText }` })
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (!config.headers.Authorization && token) {
    config.headers.Authorization = token
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  const { code, msg } = response.data

  if (code === 401) {
    console.log(msg)
    Notify({ type: 'danger', message: `登录过期，请重新登录` })
    // 获取 token
    storage.remove(ACCESS_TOKEN)
    store.dispatch('user/logout')
  }

  // noToast 不提示错误信息
  if (code === 50001 && !response?.config?.noToast) {
    Notify({ type: 'danger', message: msg || '连接服务器异常，请稍后重试'})
  }

  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Token from '@/utils/token'

export default {
  name: 'App',
  created () {
    this.disbleWxPageDown()

    const toKen = Token.get()
    if (toKen) {
      this.$store.dispatch('user/getUserInfo')
    }
  },
  methods: {
    // 禁止微信页面下拉
    disbleWxPageDown () {
      window.scrollTo(0, document.body.scrollTop + 1)
      document.body.scrollTop >= 1 && window.scrollTo(0, document.body.scrollTop - 1)
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollTop + 1)
        document.body.scrollTop >= 1 && window.scrollTo(0, document.body.scrollTop - 1)
      }, 10)
    }
  }
}
</script>

<style lang="less">
* {
  box-sizing: border-box;
}
#app {
  position: relative;
  font-family: Arial, "Microsoft YaHei", "黑体", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: @text-color;
  min-height: 100%;
  max-width: 750px;
  margin: 0 auto;
  user-select: none;
  font-size: @font-size-base;

  width: 100%;
  height:100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  background: #fff;

  &::-webkit-scrollbar-track-piece {
    background: #e7f1fc;
  }

  &::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    background: #afbac9;
    border-radius: 20px;
    border-radius: 20px;
  }
}

html, body {
  width: 100%;
  height: 100%;

  --van-gray-1: #f7f8fa;
  --van-gray-2: #f2f3f5;
  --van-gray-3: #ebedf0;
  --van-gray-4: #dcdee0;
  --van-gray-5: #c8c9cc;
  --van-gray-6: #969799;
  --van-gray-7: #646566;
  --van-gray-8: #323233;
  --van-red: #ee0a24;
  --van-blue: #1989fa;
  --van-orange: #ff976a;
  --van-orange-dark: #ed6a0c;
  --van-orange-light: #fffbe8;
  --van-green: #07c160;

  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  overflow: hidden;
}


.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// ios 滚动优化
.IOSScroll {
  -webkit-overflow-scrolling: touch;
}


.dot {
  z-index: 0;
  position: absolute;
  height: 1px;
  width: 1px;
  border-radius: 50%;
  animation: sploosh .3s ease-in;
  background: transparent;
}
@keyframes sploosh {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 350px rgba(0, 0, 0, 0.05);
  }
}


.van-uploader__preview-delete {
  .van-uploader__preview-delete-icon {
    font-size: 0.55rem;
  }
}

</style>

import Vue from 'vue'

export default {
  get (name, def) {
    return Vue.ls.get(name, def)
  },
  set (name, data, expire = null) {
    Vue.ls.set(name, data, expire)
  },
  remove (name) {
    return Vue.ls.remove(name)
  },
  clear () {
    return Vue.ls.clear()
  }
}
